let repairMixin = {
  beforeDestroy() {
    this.removeLocalStorageEvent(this.searchEvent)
  },
  mounted() {
    this.addLocalStorageEventByKey('updateWorkerList', this.searchEvent)
  },
  methods:{

  }
}

export default repairMixin